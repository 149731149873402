import "./App.css";
import Login from "./Login/Login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./Loader/Loader";

function App() {
  window.localStorage.clear();
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/signup" component={Login} />
          <Route path="/" exact component={Loader} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
// ar582179@gmail.com
