import React, { useState, useEffect } from "react";
import "./LoginBox.css";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createCookie } from "./Cookies.js";
import { Link } from "react-router-dom";
import env from "react-dotenv";

function LoginBox() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [userType, setUserType] = useState("student");

  const TogglePassword = () => {
    if (document.getElementById("visible").classList.contains("hidden")) {
      document.getElementById("password-field").type = "password";
      document.getElementById("visible").classList.remove("hidden");
      document.getElementById("invisible").classList.add("hidden");
    } else {
      document.getElementById("password-field").type = "text";
      document.getElementById("invisible").classList.remove("hidden");
      document.getElementById("visible").classList.add("hidden");
    }
  };

  const history = useHistory();

  //User verification will be done here
  const verifyUser = () => {
    //Check if fields are empty
    if (!username || !password || !userType) {
      toast.warning("Fields cannot be empty");
      return;
    }

    console.log(username, username.length);
    const email = username.trimEnd().trimStart();
    console.log(email, email.length);

    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/auth/login?user=${userType}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("response after parsing", response);
        toast.info("Logging you in!");

        if (response.status === "connecting") {
          toast.info("Sorry! Please try again after few seconds");
          return;
        }

        if (response.status === "success") {
          const token = response.data.token;
          const id = response.data.payload.userId;
          const role = response.data.payload.role;

          window.localStorage.setItem("authToken", token);
          createCookie("token", token, 10000);

          if (role === "student") {
            window.location.href = `${process.env.REACT_APP_STUDENT}/auth/${token}/${id}`;
          } else if (
            role === "teacher" ||
            role === "hod" ||
            role === "principal"
          ) {
            window.location.href = `${process.env.REACT_APP_TEACHER}/auth/${token}/${id}`;
          } else {
            window.location.href = `${process.env.REACT_APP_SCHOOL_ADMIN}/auth/${token}/${id}`;
          }
        } else {
          toast.error(response.message);
          setUserType(null);
        }
      })
      .catch(() => {
        toast.info("Sorry! Please try again in few seconds");
      });
  };

  toast.configure();

  return (
    <>
      <div className="LoginBox">
        <div className="top-section">
          <h3>Sign In</h3>
        </div>

        <div className="ToggleBox">
          <span>
            <label htmlFor="student">Student</label>
            <input
              type="radio"
              onChange={() => {
                setUserType("student");
              }}
              checked={userType === "student"}
              value="student"
              name="loginType"
              className="usertype-input"
            />
          </span>
          <span>
            <label htmlFor="teacher">Teacher</label>
            <input
              type="radio"
              onChange={() => {
                setUserType("staff");
              }}
              checked={userType === "staff"}
              value="teacher"
              name="loginType"
              className="usertype-input"
            />
          </span>
        </div>

        <div className="input-container">
          <input
            type="text"
            className="input-username"
            placeholder="Username or email address"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <span>
            <input
              type="password"
              className="input-password"
              id="password-field"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button
              className="toggle-password"
              onClick={() => {
                TogglePassword();
              }}
            >
              <VisibilityOutlinedIcon id="visible" />
              <VisibilityOffOutlinedIcon className="hidden" id="invisible" />
            </button>
          </span>
          <button
            className="signin-btn"
            onClick={() => {
              verifyUser();
            }}
          >
            Sign in to my account
          </button>
        </div>

        <div className="bottom-section">
          <p>
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </p>
          <p>
            Forgot password? <Link to="/email">Password Reset</Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default LoginBox;
