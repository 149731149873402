import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./LoginBox.css";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

function NewPassword() {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const history = useHistory();

  const TogglePassword = () => {
    if (document.getElementById("visible").classList.contains("hidden")) {
      document.getElementById("password-field").type = "password";
      document.getElementById("visible").classList.remove("hidden");
      document.getElementById("invisible").classList.add("hidden");
    } else {
      document.getElementById("password-field").type = "text";
      document.getElementById("invisible").classList.remove("hidden");
      document.getElementById("visible").classList.add("hidden");
    }
  };

  //Password updation will be done here
  const updatePassword = async () => {
    //check if password fields are empty
    if (!password || !confirmPassword) {
      toast.error("Fields cannot be empty");
      return;
    }

    //check if passwords match
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const resetToken = window.localStorage.getItem("resetToken");
    const userType = window.localStorage.getItem("userType");
    const newPassword = password;

    try {
      await fetch(
        `${window.env.REACT_APP_ORIGIN}/api/v1/auth/resetpassword?user=${userType}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newPassword, confirmPassword, resetToken }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status == "success") {
            toast.success("Passwords have been reset successfully");
            // window.location.href="https://main.d8wss6xapn3qq.amplifyapp.com/"
            // window.location.href = "/"
            history.push("/login");
          } else {
            toast.error(response.message);
            console.log(response);
          }
        });
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  toast.configure();

  return (
    <>
      <div className="LoginBox">
        <div className="top-section">
          <h3>Password</h3>
          <p>Enter your new password</p>
        </div>

        <div className="input-container">
          <span>
            <input
              type="password"
              className="input-password"
              id="password-field"
              placeholder="New Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button
              className="toggle-password"
              onClick={() => {
                TogglePassword();
              }}
            >
              <VisibilityOutlinedIcon id="visible" />
              <VisibilityOffOutlinedIcon className="hidden" id="invisible" />
            </button>
          </span>
          <input
            type="password"
            className="input-username"
            placeholder="Confirm password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />

          {confirmPassword ? (
            password === confirmPassword ? (
              <p style={{ fontWeight: "bold", color: "#14be14" }}>
                Passwords match <CheckOutlinedIcon />{" "}
              </p>
            ) : (
              <p style={{ fontWeight: "bold", color: "red" }}>
                Passwords do not match <ClearOutlinedIcon />{" "}
              </p>
            )
          ) : (
            <p>&nbsp;</p>
          )}

          <button
            className="signin-btn"
            onClick={() => {
              updatePassword();
            }}
          >
            Confirm Password
          </button>
        </div>
      </div>
    </>
  );
}

export default NewPassword;
