import React from "react";
import "./Footer.css";
import MainImage from "./Images/footer.png";
import FacebookIcon from "./Images/fb.svg";
import InstagramIcon from "./Images/ig.svg";
import LinkedinIcon from "./Images/lk.svg";
import Logo from "./Images/2xcell_logo.png";

function Footer() {
  return (
    <footer>
      <div className="upper">
        <div className="info">
          <img src={Logo} className="info-img" alt="" width={80} height={80} />
          <span>
            <a href="https://www.instagram.com/2xcell/" class="icon">
              <img src={InstagramIcon} alt="" />
            </a>
            <a href="https://www.facebook.com/2Xcell" class="icon">
              <img src={FacebookIcon} alt="" />
            </a>
            <a href="https://www.linkedin.com/company/2xcell-in/" class="icon">
              <img src={LinkedinIcon} alt="" />
            </a>
          </span>
        </div>
        <div className="links">
          <span>
            <p className="link-heading">Community</p>
            <span class="link">
              <a href="">Create Account</a>
            </span>
          </span>
          <span>
            <p className="link-heading">Support</p>
            <span class="link">
              <a href="">Terms & Conditions</a>
              <a href="https://www.2xcell.in/privacy-policy.html">Privacy Policy</a>
              <a href="">Copyright Isuue</a>
              <a href="">Get Help</a>
            </span>
          </span>
          <span>
            <p className="link-heading">Join Us</p>
            <span class="link">
              <a href="">Student</a>
              <a href="">Schools</a>
              <a href="">Contact Us</a>
              <a href="">Careers</a>
            </span>
          </span>
        </div>

        <div className="downloads">
          <p className="link-heading">Download App</p>
          <a href="">Apple ios</a>
          <a href="">Google Play</a>
        </div>
      </div>

      <div className="lower">
        <p>Copyright © 2010-2019 2xCell</p>
      </div>
    </footer>
  );
}

export default Footer;
