/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import "./LoginBox.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useHistory } from "react-router-dom";
import Popup from "./Popup"

function SignUpWithCodeBox() {
  document.title = "SignUp Page";

  toast.configure();

  const params = useParams();
  const [code, setCode] = React.useState(params.classCode || "");
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [open,setOpen] = React.useState(false)

  const submitForm = async (data) => {
    const { name, contact, email, classCode } = data;

    if (!name || !contact || !email || !classCode) {
      toast.error("Fields cannot be empty");
      return;
    }

    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/students/signupwithcode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, contact, classCode }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setOpen(true)
          } else {
            toast.error(response.message, { autoClose: false });
            console.log(response);
          }
        });
    } catch (error) {
      toast.error(error.message, { autoClose: false });
      console.log(error);
    }
  };

  return (
    <>
      <Popup open={open} setOpen={setOpen} ></Popup>
      <div className="LoginBox">
        <div className="top-section">
          <h3>Sign Up</h3>
          <p>Please provide required details</p>
        </div>

        <form onSubmit={handleSubmit(submitForm)} className="input-container">
          <input
            type="text"
            className="input-username"
            placeholder="Name"
            {...register("name")}
            required
          />
          <input
            type="tel"
            className="input-username"
            placeholder="Contact Number"
            {...register("contact")}
            required
          />
          <input
            type="email"
            className="input-username"
            placeholder="Email address"
            {...register("email")}
            required
          />
          <input
            type="text"
            className="input-username"
            placeholder="Code"
            {...register("classCode")}
            required
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />

          <button className="signin-btn" type="submit">
            Sign up new account
          </button>
        </form>

        <div className="bottom-section" style={{ textAlign: "center" }}>
          <p>
            Already have an account? <Link to="/login">Log In</Link>
          </p>
          <p>
            You are going to sign up your account.Please review our <br />{" "}
            <a href="#">Terms & Conditions.</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default SignUpWithCodeBox;
