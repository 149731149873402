/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import "./LoginBox.css";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";

function SignUpBox() {
  document.title = "SignUp Page";

  toast.configure();

  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const TogglePassword = () => {
    if (document.getElementById("visible").classList.contains("hidden")) {
      document.getElementById("password-field").type = "password";
      document.getElementById("visible").classList.remove("hidden");
      document.getElementById("invisible").classList.add("hidden");
    } else {
      document.getElementById("password-field").type = "text";
      document.getElementById("invisible").classList.remove("hidden");
      document.getElementById("visible").classList.add("hidden");
    }
  };

  const submitForm = async (data) => {
    const { name, contact, email, password, grade } = data;

    if (!name || !contact || !email || !password || !grade) {
      toast.error("Fields cannot be empty");
      return;
    }

    try {
      await fetch(`${window.env.REACT_APP_ORIGIN}/api/v1/students/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, contact, password, grade }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast.success("Signed up successfully");
            setTimeout(() => {
              // window.location.href = "/";
              history.push("/login");
            }, 800);
          } else {
            toast.error(response.message, { autoClose: false });
            console.log(response);
          }
        });
    } catch (error) {
      toast.error(error.message, { autoClose: false });
      console.log(error);
    }
  };

  const grades = [
    "LKG",
    "UKG",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    ,
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];

  return (
    <>
      <div className="LoginBox">
        <div className="top-section">
          <h3>Sign Up</h3>
          <p>Please provide required details</p>
        </div>

        <form onSubmit={handleSubmit(submitForm)} className="input-container">
          <input
            type="text"
            className="input-username"
            placeholder="Name"
            {...register("name")}
            required
          />
          <input
            type="tel"
            className="input-username"
            placeholder="Contact Number"
            {...register("contact")}
            required
          />
          <input
            type="email"
            className="input-username"
            placeholder="Email address"
            {...register("email")}
            required
          />
          <select
            className="input-username input-grade"
            placeholder="Select Grade"
            {...register("grade")}
            required
          >
            <option value="" disabled selected>
              Select Grade
            </option>
            {grades.map((grade) => (
              <option value={grade}>{grade}</option>
            ))}
          </select>
          <span>
            <input
              type="password"
              className="input-password"
              id="password-field"
              placeholder="Password"
              {...register("password")}
              required
            />
            <button
              className="toggle-password"
              type="button"
              onClick={() => {
                TogglePassword();
              }}
            >
              <VisibilityOutlinedIcon id="visible" />
              <VisibilityOffOutlinedIcon className="hidden" id="invisible" />
            </button>
          </span>
          <button className="signin-btn" type="submit">
            Sign up new account
          </button>
        </form>

        <div className="bottom-section" style={{ textAlign: "center" }}>
          <p>
            Already have an account? <Link to="/login">Log In</Link>
          </p>
          <p>
            Have a code? <Link to="/signup/code">Click here</Link>
          </p>
          <p>
            You are going to sign up your account.Please review our <br />{" "}
            <a href="#">Terms & Conditions.</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default SignUpBox;
