import React, { useState, useEffect } from "react";
import LoaderIcon from "./LoaderIcon2.svg";
import "./Loader.css";
import { useLocation, useHistory } from "react-router-dom";
const { readCookie, deleteCookies } = require("../Functions/Cookies.js");

function Loader() {
  const history = useHistory();
  //Navigate to login page
  const moveToLogin = () => {
    console.log("Redirecting to login...");
    // window.location.href = "/login"
    history.push("/login");
  };

  //E$xtract params from route if any
  const location = useLocation();
  const loggedOut = new URLSearchParams(location.search).get("logout") || false;
  console.log(loggedOut);

  //If user has been redirected after logging out, clear the token and movie to login
  if (loggedOut) {
    // deleteCookies()

    var allCookies = document.cookie.split(";");
    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < allCookies.length; i++) {
      document.cookie =
        allCookies[i] + "=;expires=" + new Date(0).toUTCString();
    }

    window.localStorage.removeItem("authToken");
    moveToLogin();
  }

  //Function to validate the details once
  const validate = async () => {
    console.log("starting validation function...");

    const token2 = window.localStorage.getItem("authToken");
    const token1 = readCookie("token");

    console.log("Token from local storage: ", token1);
    console.log("Token from cookies: ", token2);
    //If no tokens are present move to login page
    if (!token1 && !token2) {
      console.log("No token found");
      moveToLogin();
      return;
    }

    const token = token1 ? token1 : token2;
    console.log("token is", token);
    //Validate the token present
    console.log("Validating token");
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/validate/${token}`)
      .then((response) => response.json())
      .then((response) => {
        console.log("token validation response->", response);

        //If token is validate redirect to the application according to user type
        if (response.status === "authorized") {
          const id = response.data.user.userId;
          const role = response.data.user.role;

          if (role == "student") {
            window.location.href = `${process.env.REACT_APP_STUDENT}/auth/${token}/${id}`;
          } else if (role == "teacher") {
            window.location.href = `${process.env.REACT_APP_TEACHER}/auth/${token}/${id}`;
          } else {
            window.location.href = `${process.env.REACT_APP_SCHOOL_ADMIN}/auth/${token}/${id}`;
          }
        } else {
          //Move to login page if token is not validated
          moveToLogin();
        }
        // moveToLogin()
      })
      .catch(() => {
        var allCookies = document.cookie.split(";");
        // The "expire" attribute of every cookie is
        // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
        for (var i = 0; i < allCookies.length; i++) {
          document.cookie =
            allCookies[i] + "=;expires=" + new Date(0).toUTCString();
        }

        window.localStorage.removeItem("authToken");
        moveToLogin();
      });
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <div className="loading">
      <img src={LoaderIcon} alt="Loading..." />
    </div>
  );
}

export default Loader;
