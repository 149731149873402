import React, { useState } from "react";
import "./LoginBox.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

function EmailInput() {
  toast.configure();

  const [email, setEmail] = useState(null);
  const [userType, setUserType] = useState(null);
  const history = useHistory();

  //User verification will be done here
  const sendEmailAddress = async () => {
    //Check if fields are empty
    if (!email || !userType) {
      toast.error("Fields cannot be empty");
      return;
    }

    try {
      await fetch(
        `${window.env.REACT_APP_ORIGIN}/api/v1/auth/forgetpassword?user=${userType}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status == "success") {
            //Save details in localstorage for later use
            window.localStorage.setItem("email", email);
            window.localStorage.setItem("userType", userType);
            history.push("/otp");
          } else {
            toast.error(response.message, { autoClose: false });
          }
        });
    } catch (error) {
      toast.error(error.message, { autoClose: false });
    }
  };
  return (
    <>
      <div className="LoginBox">
        <div className="top-section">
          <h3>Email</h3>
          <p>Please provide the email address to send OTP</p>
        </div>

        <div className="ToggleBox">
          <span>
            <label htmlFor="student">Student</label>
            <input
              type="radio"
              onChange={() => {
                setUserType("student");
              }}
              value="student"
              name="loginType"
            />
          </span>
          <span>
            <label htmlFor="teacher">Teacher</label>
            <input
              type="radio"
              onChange={() => {
                setUserType("staff");
              }}
              value="teacher"
              name="loginType"
            />
          </span>
        </div>

        <div className="input-container">
          <input
            type="email"
            className="input-username"
            placeholder="Email address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
          <button
            onClick={() => {
              sendEmailAddress();
            }}
            className="signin-btn"
            style={{ marginTop: "1rem" }}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
}

export default EmailInput;
