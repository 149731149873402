import React from 'react'
import {Modal,Button} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./LoginBox.css"
import {useMediaQuery} from "react-responsive"

const modalStyle = {
    fontFamily:"Poppins",
    fontSize:"0.9em",
    fontWeight:"500",
    textAlign:"center"
}

export default function Popup({open,setOpen}) {
    
    const isSmall = useMediaQuery({query:"(max-width:472px)"})
    const download = ()=>{
        window.location.href = "https://play.google.com/store/apps/details?id=com.xcell"
    }
    console.log("smal ===>",isSmall)

    return (
        <Modal show={open} style={modalStyle} centered>
          <Modal.Header closeButton={isSmall?false:true} onHide={()=>setOpen(false)} >
            <Modal.Title>Signed Up Successfully 🎉</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              Dear Student, you have been successfully registered in 2xcell.<br/>
              Username is your <strong>Email Id</strong> and Password is <strong>123456</strong><br/>
              same has been mailed to you. Kindly download the app from<br/>
              below link and login to the app.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn mx-auto download-btn" onClick={download} >
              DOWNLOAD 2XCELL
            </Button>
          </Modal.Footer>
        </Modal>    
    );
  }
