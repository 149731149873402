import React from "react";
import "./Login.css";
import Navbar from "./Navbar/Navbar";
import LoginBox from "./LoginBox/LoginBox";
import SignUpBox from "./LoginBox/SignUp";
import SignUpWithCodeBox from "./LoginBox/SignUpWithCode";
import EmailInput from "./LoginBox/EmailInput";
import OTPbox from "./LoginBox/OTPbox";
import NewPassword from "./LoginBox/NewPassword";
import Footer from "./Footer/Footer";
import Wave from "./wave.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Login() {
  return (
    <div className="Login">
      <Navbar className="navbar_component"></Navbar>
      <div className="bg-1"></div>
      <img src={Wave} className="wave" alt="" />
      <Router>
        <Switch>
          {/* <Route path="/" exact>
                        <LoginBox/>
                    </Route> */}
          <Route path="/login" exact>
            <LoginBox />
          </Route>
          <Route path="/signup" exact>
            <SignUpBox />
          </Route>
          <Route path="/signup/code" exact>
            <SignUpWithCodeBox />
          </Route>
          <Route path="/signup/code/:classCode" exact>
            <SignUpWithCodeBox />
          </Route>
          <Route path="/email" exact>
            <EmailInput />
          </Route>
          <Route path="/otp" exact>
            <OTPbox />
          </Route>
          <Route path="/reset-password" exact>
            <NewPassword />
          </Route>
        </Switch>
      </Router>
      <Footer></Footer>
    </div>
  );
}

export default Login;
