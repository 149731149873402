import React from "react";
import "./Navbar.css";
import Logo from "./new-logo.png";

function Navbar() {
  return (
    <nav class="navbar">
      <div class="container">
        <div class="navbar-header">
          {/* <button class="navbar-toggler" data-toggle="open-navbar1">
            <span></span>
            <span></span>
            <span></span>
          </button> */}
          <a href="https://www.2xcell.in/">
            <h4>
              <img src={Logo} width="80px" />
            </h4>
          </a>
        </div>

        {/* <div class="navbar-menu" id="open-navbar1">
          <ul class="navbar-nav">
            <li class="active">
              <a href="https://www.2xcell.in/">Home</a>
            </li>

            <li>
              <a href="https://www.2xcell.in/#about">About</a>
            </li>
            <li>
              <a href="https://www.2xcell.in/demo.html"> Demo</a>
            </li>
            <li>
              <a href="https://www.2xcell.in/students.html"> Students </a>
            </li>
            <li>
              <a href="https://www.2xcell.in/school.html"> Schools</a>
            </li>
            <li>
              <a href="https://www.2xcell.in/contact.html">Contact Us </a>
            </li>
            <li>
              <a href="https://blog.2xcell.in/">Blog </a>
            </li>
            <li id="trial">
              <a href="https://www.2xcell.in/index.html#register"> Trial</a>
            </li>
            <li>
              <a href="https://auth.2xcell.in/" id="login">
                Login
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar;
