import React, { useState, useEffect } from "react";
import "./LoginBox.css";
import { useHistory } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createCookie } from "./Cookies.js";
import { useTimer } from "react-timer-hook";
import OtpInput from "react-otp-input";

//Timer function
function MyTimer({ expiryTimestamp }) {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return minutes == 0 && seconds == 0 ? (
    <p style={{ color: "red", fontWeight: "bold" }}>
      The session has expired. Click on resend button.{" "}
    </p>
  ) : (
    <p>
      This session will end in {minutes} minutes {seconds} seconds.
    </p>
  );
}

function OTPbox() {
  toast.configure();
  const [otp, setOtp] = useState("");
  const history = useHistory();

  const handleChange = (otp) => {
    setOtp(otp);
  };

  //OTP verification will be done here
  const verifyOtp = async () => {
    //Check if otp is of valid form or not
    if (!otp || otp.length < 4) {
      toast.error("Invalid OTP", { autoClose: false });
      return;
    }

    const email = window.localStorage.getItem("email");
    const userType = window.localStorage.getItem("userType");
    const code = parseInt(otp);

    try {
      await fetch(
        `${window.env.REACT_APP_ORIGIN}/api/v1/auth/verifycode?user=${userType}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, code }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status == "success") {
            window.localStorage.setItem("resetToken", response.resetToken);
            // window.location.href = "/reset-password";
            history.push("/reset-password");
          } else {
            toast.error(response.message);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  //function to resend OTP
  //User verification will be done here
  const resendOTP = async () => {
    const email = window.localStorage.getItem("email");
    const userType = window.localStorage.getItem("userType");

    try {
      await fetch(
        `${window.env.REACT_APP_ORIGIN}/api/v1/auth/forgetpassword?user=${userType}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status == "success") {
            // window.location.reload();
            history.push("/otp");
          } else {
            toast.error(response.message, { autoClose: false });
          }
        });
    } catch (error) {
      toast.error(error.message, { autoClose: false });
    }
  };

  return (
    <div className="LoginBox">
      <div className="top-section">
        <h3>Verify Account</h3>
        <p>Please enter OTP code, we have send to you at your email address</p>
      </div>

      <div className="input-container">
        <span className="otp-box">
          <OtpInput value={otp} onChange={handleChange} numInputs={4} />
        </span>

        <MyTimer expiryTimestamp={Date.now() + 180000} />
        <p>
          Didn’t get code?{" "}
          <button
            type="button"
            className="resend_code_btn"
            onClick={() => {
              resendOTP();
            }}
          >
            Resend Code
          </button>{" "}
        </p>
        <button
          className="signin-btn"
          style={{ marginTop: "1rem" }}
          onClick={() => {
            verifyOtp();
          }}
        >
          Verify OTP
        </button>
      </div>
    </div>
  );
}

export default OTPbox;
